import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faBars, faArrowLeft, faArrowRight, faCheck, faEnvelope, faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons'

// solid icons
library.add(
  faBars,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faEnvelope,
  faPhoneAlt,
  faMapMarkerAlt
)

// regular icons
library.add(

)

// brand icons
library.add(
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faYoutube
)

dom.watch()
